<template>
  <div class="zy-gant">
    <gant-table
      :list.sync="tableList"
      :scrollY.sync="scrollY"
      :width="tableWidth"
      :load="loadList"
    >
      <slot />
    </gant-table>
    <gant
      v-if="tableList.length"
      :list.sync="tableList"
      :scrollY.sync="scrollY"
      :day-width="gantDayWidth"
    >
    </gant>
  </div>
</template>

<script>
import Gant from "./components/gant.vue"
import GantTable from "./components/table.vue"
export default {
  components: { Gant, GantTable },
  data() {
    return {
      tableList: [],
      scrollY: 0,
    }
  },
  props: {
    //表格占宽度
    tableWidth: {
      type: Number | String,
      default: "50%",
    },
    //甘特图一日所占宽度
    gantDayWidth: {
      type: Number,
      default: 10,
    },
    // 加载函数
    request: Function
  },
  created() {
    this.loadList()
  },
  methods: {
    appedData(tree, data, list) {
      for(let i = 0; i < list.length; i++) {
        const row = list[i]
        if (!row.hasChildren) continue
        if (row.id === tree.id) {
          row.children = data
          break
        }
        if (row.children && row.children.length) {
          this.appedData(tree, data, row.children)
        }
      }
    },
    // 懒加载
    loadList(tree, treeNode, resolve) {
      if (!this.request) return
      this.request(tree, treeNode).then(res=>{
        if (resolve) resolve(res.data)
        if (!tree) {
          this.tableList = res.data
        } else {
          this.appedData(tree, res.data, this.tableList)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.zy-gant {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
}
</style>
